import loadable from '@loadable/component';
import React, { useEffect } from 'react';

import { bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const { REACT_APP_TAWK_PROPERTY_ID, REACT_APP_TAWK_WIDGET_ID } = process.env;

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  useEffect(() => {
    if (window.Tawk_API?.showWidget) {
      window.Tawk_API?.showWidget();
    }

    return () => {
      if (window.Tawk_API?.hideWidget) {
        window.Tawk_API?.hideWidget();
      }
    };
  }, []);

  return (
    <>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
      />
      {!inProgress && (
        <TawkMessengerReact
          propertyId={REACT_APP_TAWK_PROPERTY_ID}
          widgetId={REACT_APP_TAWK_WIDGET_ID}
        />
      )}
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
